const AppHeader = () => import(/* webpackChunkName: "app-header" */ '../layout/AppHeader');
const AppFooter = () => import(/* webpackChunkName: "app-footer" */ '../layout/AppFooter');

const PageNotFound = () => import(/* webpackChunkName: "page-not-found" */ '../views/404');
const Login = () => import(/* webpackChunkName: "login" */ '../views/Login');
const Home = () => import(/* webpackChunkName: "home" */ '../views/Home');
const Privacy = () => import(/* webpackChunkName: "privacy" */ '../views/Privacy');
const Faqs = () => import(/* webpackChunkName: "faqs" */ '../views/Faqs');
const AboutUs = () => import(/* webpackChunkName: "about-us" */ '../views/AboutUs');
const AyunAssetBased = () => import(/* webpackChunkName: "ayun-asset-based" */ '../views/product/AyunAssetBased');
const AyunQuantityBased = () => import(/* webpackChunkName: "ayun-quantity-based" */ '../views/product/AyunQuantityBased');
const ForgotPassword = () => import(/* webpackChunkName: "forgot-password" */ '../views/ForgotPassword');
const Blog = () => import(/* webpackChunkName: "blog" */ '../views/Blog');

const routes = [
  {
    path: "/",
    name: '',
    meta: {
      homepage: true
    },
    components: {
      header: AppHeader,
      default: Home,
      footer: AppFooter
    }
  }, {
    path: "/login",
    name: 'Login',
    components: {
      header: AppHeader,
      default: Login,
      footer: AppFooter
    }
  }, {
    path: "/privacy",
    name: 'Privacy',
    components: {
      header: AppHeader,
      default: Privacy,
      footer: AppFooter
    }
  }, {
    path: "/faqs",
    name: 'Frequently Asked Questions',
    components: {
      header: AppHeader,
      default: Faqs,
      footer: AppFooter
    }
  }, {
    path: "/about-us",
    name: 'About Us',
    components: {
      header: AppHeader,
      default: AboutUs,
      footer: AppFooter
    }
  }, {
    path: "/ayun-asset-based",
    name: 'Ayun Asset Based',
    components: {
      header: AppHeader,
      default: AyunAssetBased,
      footer: AppFooter
    }
  }, {
    path: "/ayun-quantity-based",
    name: 'Ayun Quantity Based',
    components: {
      header: AppHeader,
      default: AyunQuantityBased,
      footer: AppFooter
    }
  }, {
    path: "/forgot-password",
    name: 'Forgot Password',
    components: {
      header: AppHeader,
      default: ForgotPassword,
      footer: AppFooter
    }
  }, {
    path: "/blog/:id",
    name: 'Blog',
    components: {
      header: AppHeader,
      default: Blog,
      footer: AppFooter
    }
  }, {
    path: "*",
    component: PageNotFound
  }
];

export default routes;