import { blogCollection, currentTimeStamp } from '../config/firebase';

export const getAll = async () => {
  return blogCollection
  .orderBy('dateCreated', 'asc')
  .get()
  .then(querySnapshot => {
    let blogs = [];
    querySnapshot.forEach(doc => {
      let blog = doc.data();
      blogs.push({ id: doc.id, ...blog })
    });
    return blogs;
  });
}

export const getById = async (id) => {
  return blogCollection
  .doc(id)
  .get()
  .then(doc => {
    return { id: doc.id, ...doc.data() };
  });
}

export const create = async (payload) => {
  return blogCollection
  .add({
    title: payload.title,
    description: payload.description,
    dateCreated: currentTimeStamp,
    dateUpdated: currentTimeStamp
  });
}

export const update = async (payload) => {
  const blog = blogCollection.doc(payload.id);
  return blog.update({
    title: payload.title,
    description: payload.description,
    dateUpdated: currentTimeStamp
  })
  .then(() => ({ message: 'Successfully updated!' }))
  .catch(error => ({ message: error }));
}

export const updateImageUrl = async (payload) => {
  return blogCollection.doc(payload.id)
  .update({
    imgUrl: payload.imgUrl,
    imgThumbUrl: payload.imgThumbUrl,
    dateUpdated: currentTimeStamp
  });
}

export const destroy = async (id) => {
  return blogCollection.doc(id).delete();
}