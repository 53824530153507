import * as blogsApi from '../../api/blogs.service';
import { storage } from '../../config/firebase';

const blogs = {
  state: {
    all: [],
    blog: {},
    isEdit: false,
    imageModalState: false,
    saving: false,
    savingSuccessMessage: '',
    savingErrorMessage: '',
  },

  getters: {
    getAllBlogs(state) {
      return state.all.sort((a, b) => b.dateCreated - a.dateCreated);
    }
  },
  
  mutations: {
    SAVING_INIT(state) {
      state.saving = true;
      state.savingSuccessMessage = '';
      state.savingErrorMessage = '';
    },

    SAVING_SUCCEEDED(state, message) {
      state.saving = false;
      state.savingSuccessMessage = message;
      state.savingErrorMessage = '';
    },

    SAVING_ERROR(state, message) {
      state.saving = false;
      state.savingSuccessMessage = '';
      state.savingErrorMessage = message;
    },

    SET_BLOGS(state, blogs) {
      state.all = blogs;
    },

    SET_BLOG(state, blog) {
      state.blog = blog;
    },

    SET_BLOG_STATE(state, status) {
      state.isEdit = status
    },

    UPDATE_TITLE(state, title) {
      state.blog = { ...state.blog, title }
    },

    UPDATE_DESCRIPTION(state, description) {
      state.blog = { ...state.blog, description }
    },

    TOGGLE_IMAGE_MODAL(state) {
      state.imageModalState = !state.imageModalState
    }
  },

  actions: {
    async fetchAllAction({commit}) {
      const response = await blogsApi.getAll();
      commit('SET_BLOGS', response);
    },

    async fetchByIdAction({commit}, id) {
      const response = await blogsApi.getById(id);
      commit('SET_BLOG', response);
    },

    async updateBlogAction({commit, state}) {
      commit('SAVING_INIT');

      if (!state.blog.title || !state.blog.description) {
        commit('SAVING_ERROR', 'All fields are required!');
        return false;
      }

      try {
        await blogsApi.update(state.blog);
        commit('SAVING_SUCCEEDED', 'Successfully Saved!');
        return true;
      } catch (error) {
        commit('SAVING_ERROR', error.toString());
      }
    },

    async createBlogAction({commit, state}, form) {
      commit('SAVING_INIT');

      try {
        const blogCreation = await blogsApi.create(form);
        const task = storage.ref(`blogs/${form.image.name}`).put(form.image);
        const imageUrl = await task.snapshot.ref.getDownloadURL();

        await blogsApi.updateImageUrl({
          id: blogCreation.id,
          imgUrl: imageUrl,
          imgThumbUrl: imageUrl
        })
        commit('SAVING_SUCCEEDED', 'Successfully Saved!');
        return true;
      } catch (error) {
        commit('SAVING_ERROR', error.toString());
      }
    },

    async updateBlogImageAction({commit, state}, file) {
      try {
        const task = storage.ref(`blogs/${file.name}`).put(file);
        const imageUrl = await task.snapshot.ref.getDownloadURL();
        await blogsApi.updateImageUrl({
          id: state.blog.id,
          imgUrl: imageUrl,
          imgThumbUrl: imageUrl
        })
        commit('SAVING_SUCCEEDED', 'Image Successfully Uploaded!');
        commit('TOGGLE_IMAGE_MODAL');
        return true;
      } catch (error) {
        commit('SAVING_ERROR', error.toString());
      }
    },

    async deleteAction({}, id) {
      try {
        await blogsApi.destroy(id);
        return true;
      } catch (error) {
        return false;
      }
    }
  }
}

export default blogs;
