import * as faqsApi from '../../api/faqs.service';

const faqs = {
  state: {
    all: [],
    faq: {
      id: '',
      question: '',
      answer: '',
      createdBy: '',
      dateCreated: '',
      dateUpdated: ''
    },
    typedQuestion: '',
    modalState: false,
    saving: false,
    savingSuccessMessage: '',
    savingErrorMessage: '',
    searchIconState: 'fa fa-search'
  },

  getters: {
    searchedQuestions(state) {
      const typed = state.typedQuestion;
      const questions = state.all.filter(item => item.answer != '');
      if (typed.length >= 3) // limit to 3 input to trigger search
        return questions.filter(item => item.question.toLowerCase().includes(state.typedQuestion.toLowerCase()));
      else
        return questions;
    },

    currentQuestions(state) {
      return state.all.filter(item => item.answer != '');
    },

    newQuestions(state) {
      return state.all.filter(item => item.answer == '');
    },

    newQuestionsCounter(state) {
      const questions = state.all.filter(item => item.answer == '' || item.answer == null);
      return questions.length;
    }
  },

  mutations: {
    SEARCH_ICON(state, icon) {
      state.searchIconState = icon;
    },

    SAVING_INIT(state) {
      state.saving = true;
      state.savingSuccessMessage = '';
      state.savingErrorMessage = '';
    },

    SAVING_SUCCEEDED(state, message) {
      state.saving = false;
      state.savingSuccessMessage = message;
      state.savingErrorMessage = '';
    },

    SAVING_ERROR(state, message) {
      state.saving = false;
      state.savingSuccessMessage = '';
      state.savingErrorMessage = message;
    },

    SET_FAQS(state, faqs) {
      state.all = faqs;
    },

    TOGGLE_MODAL(state) {
      state.modalState = !state.modalState;
    },

    TYPE_QUESTION(state, question) {
      state.typedQuestion = question;
    },

    SET_FAQ_QUESTION(state, question) {
      state.faq.question = question;
    },

    SET_FAQ_ANSWER(state, answer) {
      state.faq.answer = answer;
    },

    SET_FAQ(state, faq) {
      state.faq = {
        id: faq.id,
        question: faq.question,
        answer: faq.answer,
        createdBy: faq.createdBy,
        dateCreated: faq.dateCreated,
        dateUpdated: faq.dateUpdated
      };
    },

    RESET_FORM(state) {
      state.faq = {
        id: '',
        question: '',
        answer: '',
        createdBy: '',
        dateCreated: '',
        dateUpdated: ''
      };
    }
  },

  actions: {
    async fetchAllAction({commit}) {
      const response = await faqsApi.getAll();
      commit('SET_FAQS', response);
    },

    async createAction({commit, rootState, state}) {
      commit('SAVING_INIT');

      const { id, question, answer } = state.faq;

      if (!question || !answer) {
        commit('SAVING_ERROR', 'All fields are required!');
        return false;
      }

      if (!id) {
        try {
          await faqsApi.create({
            question, answer, createdBy: rootState.user.userDetails.uid
          });
          commit('RESET_FORM');
          commit('SAVING_SUCCEEDED', 'Successfully saved!');
          return true;
        } catch (error) {
          commit('SAVING_ERROR', error.toString());
        }
      } else {
        try {
          await faqsApi.update({
            id, question, answer, createdBy: rootState.user.userDetails.uid
          });
          commit('SAVING_SUCCEEDED', 'Successfully saved!');
          return true;
        } catch (error) {
          commit('SAVING_ERROR', error.toString());
        }
      }
    },

    async fetchById({commit}, id) {
      const response = await faqsApi.getById(id);
      commit('SET_FAQ', response);
      return response;
    },

    async deleteAction({}, id) {
      try {
        await faqsApi.destroy(id);
        return true;
      } catch (error) {
        return false;
      }
    },

    async openModalAction({commit, dispatch}, id) {
      if (id)
        await dispatch('fetchById', id);
      else
        commit('RESET_FORM');

      commit('TOGGLE_MODAL');
    },

    closeModalAction({commit}) {
      commit('RESET_FORM');
      commit('TOGGLE_MODAL');
    }
  }
}

export default faqs;