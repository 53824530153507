import { faqCollection, currentTimeStamp } from '../config/firebase';

export const getAll = async () => {
  return faqCollection
  .orderBy('dateCreated', 'asc')
  .get()
  .then(querySnapshot => {
    let faqs = [];
    querySnapshot.forEach(doc => {
      let faq = doc.data();
      faqs.push({ id: doc.id, ...faq })
    });
    return faqs;
  });
}

export const getById = async (id) => {
  return faqCollection.doc(id)
  .get()
  .then(doc => {
    return { id: doc.id, ...doc.data() };
  });
}

export const create = async ({ question, answer, createdBy }) => {
  return faqCollection
  .add({
    question: question.toUpperCase(),
    answer,
    createdBy,
    dateCreated: currentTimeStamp,
    dateUpdated: currentTimeStamp
  });
}

export const update = async ({ id, question, answer }) => {
  return faqCollection.doc(id)
  .update({
    question: question.toUpperCase(),
    answer,
    dateUpdated: currentTimeStamp
  });
}

export const destroy = async (id) => {
  return faqCollection.doc(id).delete();
}