import { scheduleCollection, currentTimeStamp } from '../config/firebase';

export const getAll = async () => {
  return scheduleCollection
  .orderBy('dateCreated', 'asc')
  .get()
  .then(querySnapshot => {
    let schedules = [];
    querySnapshot.forEach(doc => {
      let schedule = doc.data();
      schedules.push({ id: doc.id, ...schedule })
    });
    return schedules;
  });
}

export const getById = async (id) => {
  return scheduleCollection.doc(id)
  .get()
  .then(doc => {
    return { id: doc.id, ...doc.data() };
  });
}

export const create = async (payload) => {
  return scheduleCollection
  .add({
    company: payload.company,
    email: payload.email,
    dateFrom: payload.dateFrom,
    dateTo: payload.dateTo,
    message: payload.message,
    isAddressed: false,
    dateCreated: currentTimeStamp,
    dateUpdated: currentTimeStamp
  });
}

export const addressScheduledMeeting = async (id) => {
  return scheduleCollection.doc(id)
  .update({
    isAddressed: true,
    dateUpdated: currentTimeStamp
  });
}

export const revertScheduledMeeting = async (id) => {
  return scheduleCollection.doc(id)
  .update({
    isAddressed: false,
    dateUpdated: currentTimeStamp
  });
}

export const destroy = async (id) => {
  return scheduleCollection.doc(id).delete();
}