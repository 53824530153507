import firebase from 'firebase'
import config from './env.constants';

import devConfig from './dev.env';
import qaConfig from './qa.env';
import uatConfig from './uat.env';
import uat2Config from './uat2.env';
import uat3Config from './uat3.env';
import preprodConfig from './preprod.env';
import prodConfig from './prod.env';
function getEnvParam(env) {
	if (env === 'dev') {
		return devConfig;
	} else if (env === 'qa') {
		return qaConfig;
	} else if (env === 'uat') {
		return uatConfig;
	} else if (env === 'uat2') {
		return uat2Config;
	} else if (env === 'uat3') {
		return uat3Config;
	} else if (env === 'preprod') {
		return preprodConfig;
	} else if (env === 'prod') {
		return prodConfig;
	}
}

firebase.initializeApp(getEnvParam(config.currEnv));

const auth = firebase.auth();
const currentUser = auth.currentUser;
const db = firebase.firestore();
const storage = firebase.storage();
const currentTimeStamp = firebase.firestore.FieldValue.serverTimestamp();

const userCollection = db.collection('users');
const blogCollection = db.collection('blogs');
const faqCollection = db.collection('faqs');
const scheduleCollection = db.collection('schedules');

export {
	auth,
	storage,
	currentUser,
	currentTimeStamp,
	userCollection,
	blogCollection,
	faqCollection,
	scheduleCollection
}