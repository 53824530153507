import * as schedulesApi from '../../api/schedules.service';
import Vue from 'vue';

export default {
  state: {
    schedules: [],
    schedule: {
      id: '',
      company: '',
      email: '',
      dateFrom: '',
      dateTo: '',
      message: '',
      isAddressed: '',
      dateCreated: '',
      dateUpdated: ''
    },
    modalState: false,
    isLoading: false,
    saving: false,
    savingSuccessMessage: '',
    savingErrorMessage: ''
  },
  
  getters: {
    addressedSchedules: (state) => state.schedules.filter(item => item.isAddressed),
    unAddressedSchedules: (state) => state.schedules.filter(item => !item.isAddressed),
    unAddressedSchedulesCounter: (state) => state.schedules.filter(item => !item.isAddressed).length,
    addressedSchedulesCounter: (state) => state.schedules.filter(item => item.isAddressed).length
  },
  
  mutations: {
    SET_LOADER(state, isLoading) {
      state.isLoading = isLoading;
    },

    SAVING_INIT(state) {
      state.saving = true;
      state.savingSuccessMessage = '';
      state.savingErrorMessage = '';
    },

    SAVING_SUCCEEDED(state, message) {
      state.saving = false;
      state.savingSuccessMessage = message;
      state.savingErrorMessage = '';
    },

    SAVING_ERROR(state, message) {
      state.saving = false;
      state.savingSuccessMessage = '';
      state.savingErrorMessage = message;
    },

    FILL_SCHEDULES(state, schedules) {
      state.schedules = schedules;
    },

    FILL_SCHEDULE(state, schedule) {
      state.schedule = {
        id: schedule.id,
        company: schedule.company,
        email: schedule.email,
        dateFrom: schedule.dateFrom,
        dateTo: schedule.dateTo,
        message: schedule.message,
        isAddressed: schedule.isAddressed,
        dateCreated: schedule.dateCreated,
        dateUpdated: schedule.dateUpdated
      };
    },

    RESET_SCHEDULE(state) {
      state.schedule = {
        id: '',
        company: '',
        email: '',
        dateFrom: '',
        dateTo: '',
        message: '',
        isAddressed: '',
        dateCreated: '',
        dateUpdated: ''
      };
    },

    TOGGLE_MODAL(state) {
      state.modalState = !state.modalState;
    },
  },

  actions: {
    async fetchAllAction({commit}) {
      const response = await schedulesApi.getAll();
      commit('FILL_SCHEDULES', response);
    },

    async fetchById({commit}, id) {
      const response = await schedulesApi.getById(id);
      commit('FILL_SCHEDULE', response);
      return response;
    },

    async sendScheduleAction({commit}, payload) {
      commit('SET_LOADER', true);
      const { company, email, dateFrom, dateTo, message } = payload;

      if (!company || !email || !dateFrom || !dateTo || !message) {
        Vue.toaster.error('Oops! Please complete all the fields.');
        return false;
      }

      try {
        await schedulesApi.create(payload);
        commit('SET_LOADER', false);
        Vue.toaster.success('Schedule has been sent! Please check your email regularly, thank you!');
        return true;
      } catch (error) {
        commit('SET_LOADER', false);
        Vue.toaster.error('Oops! Something went wrong, please try again.');
        return false;
      }
    },

    async addressScheduledMeetingAction({commit, state}) {
      commit('SAVING_INIT');

      if (!state.schedule.id) {
        commit('SAVING_ERROR', 'Please select schedule!');
        return false;
      }

      try {
          await schedulesApi.addressScheduledMeeting(state.schedule.id);
          commit('SAVING_SUCCEEDED', 'Meeting approved and moved into current tab.');
          return true;
        } catch (error) {
          console.log(error.toString());
          commit('SAVING_ERROR', 'Oops! something went wrong. Please try again.');
        }
    },

    async revertScheduledMeetingAction({commit, state}) {
      commit('SAVING_INIT');

      if (!state.schedule.id) {
        commit('SAVING_ERROR', 'Please select schedule!');
        return false;
      }

      try {
          await schedulesApi.revertScheduledMeeting(state.schedule.id);
          commit('SAVING_SUCCEEDED', 'Meeting has been reverted and moved back to new tab.');
          return true;
        } catch (error) {
          console.log(error.toString());
          commit('SAVING_ERROR', 'Oops! something went wrong. Please try again.');
        }
    },

    async deleteAction({}, id) {
      try {
        await schedulesApi.destroy(id);
        return true;
      } catch (error) {
        return false;
      }
    },

    async openModalAction({commit, dispatch}, id) {
      if (id)
        await dispatch('fetchById', id);
      else
        commit('RESET_FORM');

      commit('TOGGLE_MODAL');
    },

    closeModalAction({commit}) {
      commit('RESET_FORM');
      commit('TOGGLE_MODAL');
    }
  }
}
