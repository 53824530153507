import { auth, userCollection } from '../../config/firebase'
import router from '../../router'
import { SUPERADMIN } from '../../config/constants'
import { isEmpty } from '../../utils/stringUtils'

const user = {
  state: {
    isAuthenticating: false,
    loginErrorCode: '',
    loginErrorMessage: '',
    loginSuccessMessage: '',
    userDetails: {}
  },

  getters: {
    isAuthenticating: state => state.isAuthenticating,
    isAuthenticated: state => isEmpty(state.userDetails) ? false : true,
    getUserDetails: state => state.userDetails,
    loginErrorMessage: state => state.loginErrorMessage,
    loginSuccessMessage: state => state.loginSuccessMessage
  },

  mutations: {
    SET_LOADING: (state) => {
      state.isAuthenticating = true;
      state.loginErrorCode = '';
      state.loginErrorMessage = '';
      state.loginSuccessMessage = '';
    },

    SET_LOGIN_ERROR: (state, payload) => {
      state.isAuthenticating = false;
      state.loginErrorCode = payload.code;
      state.loginErrorMessage = payload.message;
      state.loginSuccessMessage = '';
    },

    SET_LOGIN_SUCCESS: (state, payload) => {
      state.isAuthenticating = false;
      state.loginErrorCode = '';
      state.loginErrorMessage = '';
      state.loginSuccessMessage = payload;
    },

    SET_USER_DETAILS: (state, payload) => {
      state.userDetails = payload
    }
  },
  
  actions: {
    loginAction({commit}, payload) {
      commit('SET_LOADING');
      const { email, password } = payload
      const msg = 'User successfully logged in!';

      auth.signInWithEmailAndPassword(email, password)
      .then(() => {
        commit('SET_LOGIN_SUCCESS', msg)
        router.replace('/')
      })
      .catch(error => {
        commit('SET_LOGIN_ERROR', error)
      })
    },

    logoutAction: ({commit}) => {
      auth.signOut()
      .then(() => {
        commit('SET_USER_DETAILS', {});
        router.replace('/login')
      });
    },

    setUserDetailsAction({commit}, payload) {
      const user = {
        email: payload.email,
        emailVerified: payload.emailVerified,
        uid: payload.uid
      }

      userCollection.doc(user.email)
      .get()
      .then(doc => {
        if (doc.exists) {
          const data = doc.data();
          commit('SET_USER_DETAILS', {
            ...user,
            isSuperAdmin: data.emailAddress == SUPERADMIN ? true : false
          })
        }
      })
      .catch((error) => {
        console.log("Error getting cached document:", error);
      });
    }
  }
}

export default user